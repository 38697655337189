<template>
  <div class="pt-32pt">
    <div :class="containerClass" class="d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
      <div class="back-result-lg back-pg-header font-weight-bold" v-if="showBack">
        <a href="#" @click.prevent="goBack()" style="text-decoration: underline">
          <span class="material-icons icon-32pt">navigate_before</span>
        </a>
      </div>

      <div :class="{ 'mb-24pt mb-md-0': !!buttonLabel }" class="flex d-flex flex-column flex-sm-row align-items-center">
        <div class="mb-24pt mb-sm-0 mr-sm-24pt">
          <page-heading class="mb-0" :element="pageHeadingElement" :element-props="pageHeadingElementProps">
            {{ title }}
            <i
              v-if="infoText.length"
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top
              :title="infoText"
              >info</i
            >
          </page-heading>

          <b-breadcrumb v-if="breadcrumb" :items="breadcrumb" class="mb-0" />
        </div>
      </div>

      <!-- button named slot -->
      <slot name="button">
        <div v-if="buttonLabel" class="row">
          <div v-b-popover.hover.right :title="hover" class="col-auto">
            <b-btn :to="buttonPath" :variant="buttonVariant">{{ buttonLabel }}</b-btn>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import PageHeading from './PageHeading.vue';

export default {
  components: {
    PageHeading,
  },
  mixins: [],
  props: {
    buttonLabel: {
      type: String,
      default: null,
    },
    buttonPath: {
      type: [String, Object],
      default: null,
    },
    buttonVariant: {
      type: String,
      default: 'outline-secondary',
    },
    breadcrumb: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: null,
    },
    containerClass: {
      type: String,
      default: null,
    },
    pageHeadingElement: {
      type: [String, Object],
    },
    pageHeadingElementProps: {
      type: Object,
    },
    hover: { type: String, default: '' },
    infoText: { type: String, default: '' },
    showBack: { type: Boolean, default: false },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .back-pg-header {
    display: none;
  }
}
</style>